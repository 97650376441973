import { Title } from '@solidjs/meta';
import { ActivityIndicator } from '@troon/ui';
import { clientOnly } from '@solidjs/start';

const LogoutAction = clientOnly(async () => ({ default: (await import('./components/logout-action')).LogoutAction }));

export default function Logout() {
	return (
		<div class="mx-auto flex max-w-xl grow items-center justify-center">
			<Title>Log out | Troon</Title>
			<div class="rounded border border-neutral-500 bg-white p-8 text-brand-600">
				<ActivityIndicator>Logging out…</ActivityIndicator>
				<LogoutAction />
			</div>
		</div>
	);
}
